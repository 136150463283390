import { defineComponent } from 'vue';
import EventBus from '@/EventBus';
export default defineComponent({
  name: 'home-story',
  mounted (){
    var element : any
    EventBus.on('my-falken-home', (evt : any) => {
      if (evt.eventContent == 'falkenAboutUs') {
        element = document.getElementById("falkenAboutUs");
        console.log('falkenAbout', element)
        var team = element.offsetTop;
        window.scrollTo(0, team);
      }
    })
  }
});