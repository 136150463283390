import { defineComponent } from "vue";
import EventBus from "@/EventBus";

export default defineComponent({
  name: "falken-header",
  data() {
    return {
      showMobileMenu: false,
    };
  },
  computed: {
    stamfordURL() {
      return process.env.VUE_APP_STAMFORD_URL
    }
  },
  methods: {
    goTO(refName: any) {
      if (this.$route.name == 'tyre-range' || this.$route.name == 'news-room' || this.$route.name == 'news-room-details') {
        console.log('ffffff',refName)
        if (refName == 'contactUsHome') {
          const timer: ReturnType<typeof setTimeout> = setTimeout(function () {
            EventBus.emit("my-event", { eventContent: refName });
          }, 1200);
          this.$router.push({name: 'falken-home'})
        } else {
          const timer: ReturnType<typeof setTimeout> = setTimeout(function () {
            EventBus.emit("my-falken-home", { eventContent: refName });
          }, 1200);
          this.$router.push({name: 'falken-home'})
        }
      } else {
        if (refName == 'contactUsHome') {
          EventBus.emit("my-event", { eventContent: refName });
        } else {
          EventBus.emit("my-falken-home", { eventContent: refName });
        }
      }
    },
  },
});
