<section class="tyre_range_tabs p-0" id="tyreRange">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation" v-for="(row,index) in tyreList">
      <button
        class="nav-link"
        :class="index== 0 ?'active' :''"
        :id="row.name+'-tab'"
        data-bs-toggle="tab"
        :data-bs-target="'#'+row.name"
        type="button"
        role="tab"
        :aria-controls="row.name"
        aria-selected="true"
        @click="openInfo(row.name)"
      >
        <div class="thumb_wrap">
          <img :src="getImgUrl(row.image)" :alt="row.name" />
          <img
            :src="getImgUrl(row.tyre_image)"
            alt="tyre"
            class="img-fluid tyre"
          />
          <span>{{row.name}}</span>
        </div>
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      v-for="(row,index) in tyreList"
      class="tab-pane fade"
      :class="index== 0 ?'active show' :''"
      :id="row.name"
      role="tabpanel"
      :aria-labelledby="row.name+'-tab'"
    >
      <ul
        class="nav nav-tabs"
        :id="'myTabInner'+index"
        role="tablist"
        v-if="row.typeCount > 0"
      >
        <li
          class="nav-item"
          role="presentation"
          v-for="(type,n) in row.tyreType"
        >
          <button
            class="nav-link"
            :class="n == 0 ?'active' :''"
            :id="type.name+'-tab'"
            data-bs-toggle="tab"
            :data-bs-target="'#'+type.name"
            type="button"
            role="tab"
            :aria-controls="type.name"
            aria-selected="true"
          >
            <span v-html="type.title"></span>
          </button>
        </li>
      </ul>
      <div
        class="tab-content ss"
        :id="'myTabInnerContent'+index"
        v-if="row.typeCount > 0"
      >
        <div
          v-for="(type,n) in row.tyreType"
          class="tab-pane fade"
          :class="n == 0 ?'active show' :''"
          :id="type.name"
          role="tabpanel"
          :aria-labelledby="type.name"
        >
          <section class="wrapper top_content">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-lg-3 d-flex align-items-center">
                  <div class="slider">
                    <div
                      :id="type.name+n"
                      class="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div
                          class="carousel-item"
                          v-for="(slide,i) in type.slider"
                          :class="i == 0 ?'active' :''"
                          data-interval="1000"
                        >
                          <a :data-fancybox="getImgUrl(slide.image)" :href="getImgUrl(slide.image)">
                            <img
                              :src="getImgUrl(slide.image)"
                              class="d-block w-100"
                              :alt="slide.alt"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="carousel-indicators">
                        <button
                          v-for="(slide,i) in type.slider"
                          type="button"
                          :data-bs-target="'#'+type.name+n"
                          :data-bs-slide-to="i"
                          :class="i == 0 ?'active' :''"
                          aria-current="true"
                          :aria-label="'Slide'+i"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-lg-3 d-flex align-items-center order-lg-3"
                >
                  <div class="video">
                    <iframe
                      v-if="type.video"
                      :src="type.video"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 d-flex align-items-center">
                  <div class="text_wrapper">
                    <div class="text">
                      <p class="sub_title">{{type.subTitle}}</p>
                      <h2 class="title" v-html="type.title"></h2>
                      <p>{{type.description}}</p>

                      <ul class="list_square" v-if="type.features">
                        <li v-for="fs in type.features" v-html="fs"></li>
                      </ul>
                    </div>
                    <ul class="btn_group d-none d-lg-flex">
                      <li>
                        <a
                          :href="type.brochure"
                          download="falken-brochure.pdf"
                          target="_blank"
                          class="btn green_btn"
                          >DOWNLOAD PRODUCT BROCHURE</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="product_portfolio">
            <div class="container">
              <p class="sub_title" v-html="type.title"></p>
              <h2 class="title">PRODUCT PORTFOLIO</h2>
              <div class="wrapper">
                <ul class="list_square">
                  <li v-for="pp in type.product_portfolo">{{pp}}</li>
                </ul>
                <ul class="partner_list">
                  <li v-for="(part,num) in type.partner">
                    <img :src="getImgUrl(part.image)" alt="partner" />
                  </li>
                </ul>
                <img
                  class="product_portfolo_image"
                  :src="getImgUrl(type.product_portfolo_image)"
                  alt="partner"
                />
              </div>
            </div>
          </section>

          <section class="advanced_features overlay_bg">
            <div class="container">
              <h2 class="title white text-center">
                ADVANCED FEATURES <span v-html="type.title"></span>
              </h2>
              <!-- <p class="text-center white">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p> -->
              <ul class="list">
                <li v-for="(feature,ind) in type.advanced_features">
                  <div class="box white_bg">
                    <div class="top">
                      <div class="img">
                        <img :src="getImgUrl(feature.image)" alt="feature.title" />
                      </div>
                    </div>
                    <div class="text">
                      <h4 class="title border_none">{{feature.title}}</h4>
                      <p class="list_title">PRODUCT FEATURE</p>
                      <ul
                        class="list_square"
                        v-if="feature.product_features.length > 0"
                      >
                        <li v-for="pf in feature.product_features">{{pf}}</li>
                      </ul>

                      <p class="list_title" v-if="feature.function.length > 0">
                        FUNCTION
                      </p>
                      <ul
                        class="list_square"
                        v-if="feature.function.length > 0"
                      >
                        <li v-for="ff in feature.function">{{ff}}</li>
                      </ul>

                      <p class="list_title" v-if="feature.benefit.length > 0">
                        BENEFIT
                      </p>
                      <ul class="list_square" v-if="feature.benefit.length > 0">
                        <li v-for="fb in feature.benefit">{{fb}}</li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
