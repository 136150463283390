<section class="news_details">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-xxl-8">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-xxl-12">
            <h2 class="title border_none">
              {{title}}
              <small class="sub_title d-block" v-if="subTitle"
                >{{subTitle}}</small
              >
            </h2>
            <div class="big_img">
              <img
                :src="getImgUrl(bannerImage)"
                :alt="title"
                class="img-fluid w-100"
              />
            </div>
            <small
              class="img_small_text proxima_med fst-italic mt-2 mb-4 d-block" v-if="bannerImageText"
              >{{bannerImageText}}</small
            >
            <p>
              {{description}}
            </p>
          </div>
        </div>
        <div v-if="id == 1">
        <div class="row justify-content-center">
          <div class="col-md-4 col-lg-4 col-xxl-4">
            <div class="big_img">
              <img
                src="@/assets/images/falken/falken-news-page-content/figure-4-supadrift-series-champions.jpg"
                alt="Figure 4 SupaDrift Series Champions"
                class="img-fluid w-100"
              />
            </div>
            <small
              class="img_small_text proxima_med fst-italic mt-2 mb-4 d-block"
              >Figure 4 SupaDrift Series Champions</small
            >
          </div>
          <div class="col-md-4 col-lg-4 col-xxl-4">
            <div class="big_img">
              <img
                src="@/assets/images/falken/falken-news-page-content/figure-2stamford-team-with-zein.jpg"
                alt="Figure 3 Zanil in Falkne gear"
                class="img-fluid w-100"
              />
            </div>
            <small
              class="img_small_text proxima_med fst-italic mt-2 mb-4 d-block"
              >Figure 3 Zanil in Falkne gear</small
            >
          </div>
          <div class="col-md-4 col-lg-4 col-xxl-4">
            <div class="big_img">
              <img
                src="@/assets/images/falken/falken-news-page-content/figure-3-zanil-in-falkne-gear.jpg"
                alt="FALKEN TYRES, A PROUD SPONSOR OF THE SUPADRIFT"
                class="img-fluid w-100"
              />
            </div>
            <small
              class="img_small_text proxima_med fst-italic mt-2 mb-4 d-block"
              >Figure 4 SupaDrift Series Champions</small
            >
          </div>
        </div>
        <p>
          This duo, both from Mozambique boasted with the Falken Azenis FK510 on
          the track, which offered them spectacular grip and traction in the
          rain. Our friends from XS Drift also didn’t hold back on the track.
          The duo, Mikey and Jim always puts their best foot (or should we say
          Tyre) forward. They were both featured in the top 6 of the
          championship and dominated the tracks with Falken Azenis FK510.
        </p>
        <p>
          The FK510 is an ultra-high performance tyre which is responsive to wet
          and dry conditions, and it enhaces driving experience. We must admit,
          we would have enjoyed more tyre burning, but witnessing the
          controllability and precision offered by the FK510 in unfavourable
          weather conditions, is a sight we can never get tired of!
        </p>
        <p>
          Other tyres available in South Africa includes the Ziex ZE914 Ecorun
          (a hybrid vehicle tyre), the Sincera SN832i (a standard tyre with a
          European flavour) and the Wildpeak A/T (for the all performance sport
          utility and light truck tyre market).
        </p>
        <div class="row">
          <div class="col-md-6 col-lg-4 col-xxl-4">
            <div class="big_img">
              <img
                src="@/assets/images/falken/falken-news-page-content/figure-5-stacey-lee-spinning-at-the-supadrift.jpg"
                alt="Figure 5 Stacey-lee spinning at the SupaDrift"
                class="img-fluid w-100"
              />
            </div>
            <small
              class="img_small_text proxima_med fst-italic mt-2 mb-4 d-block"
              >Figure 5 Stacey-lee spinning at the SupaDrift</small
            >
          </div>
          <div class="col-lg-8 col-xxl-8">
            <p>
              On the spin side, a good friend of the brand Stacey-Lee May, also
              known as Queen of Smoke, kept us entertained and burned some
              FK510s as she showed off her spinning and stunt skills. You can
              catch more of Stacey with the XS Drift team on their first episode
              of Driftfari 2021. Stamford Tyres is a proud sponsor of Falken
              tyres in the DriftFari Season 2 show that will air on Ignition TV,
              (Driftfari is a 4/6-part mini series).
            </p>
          </div>
        </div>
        <p>
          Stamford Tyres is a proud sponsor of XS Drift. Read more
          <a
            href="https://satreads.com/2021/11/03/stamford-tyres-partners-with-xs-drift-team/"
            target="_blank"
            >here.</a
          >
        </p>
        </div>
      </div>
    </div>
  </div>
</section>
