<template>
  <div class="home fl">
    <Header />
    <HomeBanner />
    <TabsSection />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/falken/Header/Header.vue";
import HomeBanner from "@/components/falken/HomeBanner/HomeBanner.vue";
import TabsSection from "@/components/falken/tyre-range/TabsSection/TabsSection.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    HomeBanner,
    TabsSection,
  },
});
</script>
