<section class="news_banner p-0">
  <div class="banner_slider_wrapper">
    <div class="box">
      <div class="container wrapper">
        <div class="text opacity-1">
          <h1 class="heading_title title_2 white text-uppercase fst-italic">
            NEWSROOM
          </h1>
        </div>
      </div>
      <img
        src="@/assets/images/falken/falken-news-page-content/newsroom-banner.webp"
        class="d-block w-100"
        alt="Falken Banner Image"
      />
    </div>
  </div>
</section>

<section class="newspage">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div
        class="col-md-5 col-lg-4 col-xxl-3 d-flex align-items-center justify-content-end"
        v-for="(row,index) in roomList"
      >
        <div class="wrap" @click="roomDetails(row)">
          <div class="img">
            <a href="#">
              <img :src="getImgUrl(row.image)" :alt="row.title" class="img-fluid"
            /></a>
          </div>
          <div class="btn_group">
            <a
              href="#"
              class="btn"
              :class="index % 2 == 0 ?'blue_btn':'green_btn'"
              ><span>{{row.title}}</span></a
            >
          </div>
        </div>
      </div>
      <div
        class="col-lg-12 d-flex align-items-center justify-content-center mt-md-5"
      >
        <div class="btn_group justify-content-center">
          <a
            href="static/pdf/falken/falken-oe-list/falken-oe-list.pdf"
            target="_blank"
            class="btn blue_btn"
            >Falken OE List</a
          >
          <a
            href="static/pdf/falken/falken-sumo-firnza/falken-sumo-firnza.pdf"
            download="static/pdf/falken/falken-sumo-firnza/falken-sumo-firnza.pdf"
            class="btn green_btn"
            >Sumo Firenza Retread Guarantee</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
