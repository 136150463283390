<template>
  <div class="home fl">
    <Header />
    <HomeBanner />
    <HomeStory />
    <TyrePatterns />
    <!-- <FindDealer /> -->
    <ThePulse />
    <FalkenWarranty />
    <SocialMedia />
    <HomeContact />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/falken/Header/Header.vue";
import HomeBanner from "@/components/falken/HomeBanner/HomeBanner.vue";
import HomeStory from "@/components/falken/HomeStory/HomeStory.vue";
import TyrePatterns from "@/components/falken/TyrePatterns/TyrePatterns.vue";
// import FindDealer from "@/components/falken/FindDealer/FindDealer.vue";
import ThePulse from "@/components/falken/ThePulse/ThePulse.vue";
import FalkenWarranty from "@/components/falken/FalkenWarranty/FalkenWarranty.vue";
import SocialMedia from "@/components/falken/SocialMedia/SocialMedia.vue";
import HomeContact from "@/components/falken/HomeContact/HomeContact.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    HomeBanner,
    HomeStory,
    TyrePatterns,
    // FindDealer,
    ThePulse,
    FalkenWarranty,
    SocialMedia,
    HomeContact
  },
  created() {
    window.scrollTo(0, 0)
  },
});
</script>
