<template>
  <div class="home fl">
    <Header />
    <NewsPage />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/falken/Header/Header.vue";
import NewsPage from "@/components/falken/NewsRoom/NewsRoom.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    NewsPage,
  }
});
</script>
