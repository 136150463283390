<section class="social_media_sec overlay_bg" id="falkenContactUs">
  <div class="container">
    <p class="white text-center sub_title">
      Falken tyres are distributed by Sumitomo Rubber South Africa (Pty) Ltd in
      select African countries. Under the guidance of Sumitomo Rubber
      Industries, Ltd, the 5th largest tyre manufacturing company in the world,
      Falken tyres has successfully built a reputation as a leader in high
      performance tyre technology for both cars and trucks.
    </p>
    <h2 class="title text-center white border_none">
      FOLLOW US ON SOCIAL MEDIA
    </h2>
    <ul class="socail_list">
      <li>
        <a href="https://www.facebook.com/FalkenTyresSA/  " target="_blank">
          <img
            src="@/assets/images/icons/facebook.webp"
            alt="Facebook"
          />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/falkentyressa" target="_blank">
          <img src="@/assets/images/icons/twitter.webp" alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UCRvTX4w513AGcbm6os-zJ9w"
          target="_blank"
        >
          <img src="@/assets/images/icons/youtube.webp" alt="Youtube" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/falken_tyres/ " target="_blank">
          <img
            src="@/assets/images/icons/instagram.webp"
            alt="Instagram"
          />
        </a>
      </li>
      <li>
        <a href="https://www.tiktok.com/@falken_tyres_sa " target="_blank">
          <img src="@/assets/images/icons/tik-tok.webp" alt="tik-tok" />
        </a>
      </li>
    </ul>
  </div>
</section>
