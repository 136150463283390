<section class="the_story p-0">
  <div class="container-fluid p-0">
    <div class="row g-0 flex-md-row-reverse">
      <div class="col-lg-6">
        <div class="img">
          <img
            src="@/assets/images/falken/falken-warranty.webp"
            alt="FALKEN WARRANTY"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-end">
        <div class="text">
          <!-- <p class="sub_title">LOREM IPSUM DOLOR</p> -->
          <h2 class="title">FALKEN MILEAGE WARRANTY</h2>
          <p>
            The Falken Mileage Warranty is issued by Stamford Tyres Africa PTY
            LTD on Falken Wildpeak AT3WA, Ziex ZE914 Ecorun and Ziex ZE310 Ecorun
            (Specific sizes). Mileage Warranty aims to protect drivers against
            premature tread wear, guaranteeing a specified mileage for tyre use
            before they wear out. The warranty is Free of Charge and it is
            available to customers who purchase 4 or more tyres from any
            participating dealer.
          </p>
          <div class="btn_group mb-5">
            <a
              href="http://warranty.falkentyres.co.za/"
              target="_blank"
              class="btn blue_btn"
              >REGISTER YOUR WARRANTY</a
            >
          </div>
          <h2 class="title">FALKEN LIFE WARRANTY</h2>
          <p>Falken Tyres are also sold with an 8 year life warranty. This warranty is a manufactures warranty against defects in material and/or workmanship.  All Sumitomo Rubber South Africa (SRSA) including Falken Tyre purchased from Falken Dealers come with this warranty.</p>
          <div class="btn_group">
            <a
              href="static/pdf/falken/falken-tyre-life-warranty/falken-tyre-life-warranty.pdf"
              download="falken-tyre-life-warranty.pdf"
              class="btn green_btn"
              >DOWNLOAD FALKEN LIFE WARRANTY</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
