import { createApp } from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import VueGtag from 'vue-gtag'
import App from './App.vue';
import router from './router';
import store from './store';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { IReCaptchaLoaderOptions } from 'recaptcha-v3/dist/ReCaptchaLoader';
export interface IReCaptchaOptions {
    siteKey: string;
    loaderOptions: IReCaptchaLoaderOptions;
};

const app = createApp(App);
app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA,  loaderOptions: {useEnterprise: false}
} as IReCaptchaOptions
);
app.use(store);
app.use(router);
app.use(VueGtag, {
    config: {
      id: 'G-R9X1CS7E49',
      params: {
        send_page_view: false
      }
    }
  })

app.mount('#app');

import 'bootstrap/dist/js/bootstrap.js';

