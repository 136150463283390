import { defineComponent } from 'vue';
import config from '@/config/app';
import { AnyObject } from 'yup/lib/types';
export default defineComponent({
  name: 'news-page',
  data() {
    return {
      roomList:config.newsRoomDetails,
      title:'',
      subTitle:'',
      bannerImage:'',
      bannerImageText:'',
      description:'',
      id:''
    }
  },
  methods: {
    getImgUrl(pic : any) {
      var images = require.context('@/assets/images/falken/falken-news-page-content/')
      return images('./' + pic)
    }
  },
  created() {
   
    var room : any
    room = this.$route.query.news_room
    // console.log('room number', room)
    if (room < 5 && room !== 0) {
      this.roomList.forEach(element => {
        if (element.id == room) {
          this.title = element.title
          this.subTitle = element.subTitle
          this.bannerImage = element.image
          this.bannerImageText = element.bannerImageText
          this.description = element.description
          this.id = element.id
        }
      });
    } else {
      this.$router.push({ path: 'news-room'})
    }
    // console.log('ggggfff', this.roomList[room].id)

    

  },
});