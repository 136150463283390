<section class="the_story p-0 black2_bg white" id="falkenAboutUs">
  <div class="container-fluid p-0">
    <div class="row g-0 flex-md-row-reverse">
      <div class="col-lg-6">
        <div class="img">
          <img
            src="@/assets/images/falken/falken-car.webp"
            alt="The Stroy"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-end">
        <div class="text">
          <p class="sub_title">The Falken tyre brand was launched in Japan in 1983</p>
          <h2 class="title title_2 white">THE STORY</h2>
          <div
            id="carouselExampleCaptions"
            class="carousel slide slider_wrapper"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <p>
                  The Falken tyre brand was launched in Japan in 1983 by
                  Sumitomo Rubber Industries, Ltd (SRI), initially as a flagship
                  High Performance passenger brand. In 1985, Falken Tyre
                  Corporation was opened in North America and the brand has
                  since grown to be recognised as one of the world leaders in
                  high performance tyres with offices and distribution around
                  the globe. With proven racetrack credentials and access to
                  advanced manufacturing technology and facilities across 11
                  international SRI factories, the Falken brand now offers a
                  comprehensive range of tyres encompassing passenger and
                  SUV/4x4 tyres.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
