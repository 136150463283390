<section class="tyres_patterns">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center">
        <div class="text">
          <p class="sub_title">From racetrack to road</p>
          <h2 class="title">TYRE PATTERNS</h2>
          <p class="fw_500">
            Whether you drive a passenger car, CUV, SUV or Light Truck, we have the exact tyre to suit your needs. Falken’s tyres are time-tested on the street, racetrack and off-road, and come up first every time, every day.  Go with capability and the strength of experience when selecting your new set of tyres.  It’s time for Falken.
          </p>

          <router-link :to="{ path: '/tyre-range' , hash: '#tyreRange'}" class="btn blue_btn">VIEW OUR PATTERNS</router-link>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="bottom_border"></div>
