<header class="navigation">
  <div class="container wrapper">
    <div class="logo">
      <router-link :to="{ path: '/'}">
        <img
          src="@/assets/images/falken/falken.webp"
          alt="falken Tyres"
          class="img-fluid"
        />
      </router-link>
    </div>
    <nav
      id="navMenu"
      class="menu"
      :class="{ active: showMobileMenu }"
      @click="showMobileMenu = false"
    >
      <div
        id="ToggleIcon"
        @click="showMobileMenu = !showMobileMenu"
        class="menu_bar close_toggle d-block d-lg-none"
        :class="{ active: showMobileMenu }"
      ></div>
      <ul class="menu_list">
        <li><a href="javascript:;" @click="goTO('falkenAboutUs')">ABOUT</a></li>
        <li class="dropdown">
          <!-- <a href="#"
            >TYRE BRANDS
            <img
              src="../../../assets/images/icons/down-bluewebp"
              alt="Down Icon"
            />
          </a> -->

          <router-link :to="{ path: '/tyre-range'}"
            >FALKEN PATTERNS
          </router-link>
        </li>
        <!-- <li><a href="#">DEALER LOCATOR</a></li> -->
        <li>
          <a href="https://orders.stamford.co.za/" target="_blank">B2B</a>
        </li>
        <li>
          <router-link :to="{ path: '/news-room'}"
            >News Room
          </router-link>
        </li>
      </ul>
      <ul class="btn_group_list d-flex d-lg-none">
        <li>
          <a href="javascript:;" @click="goTO('contactUsHome')" class="btn blue_btn"
            >Contact Us</a
          >
        </li>
        <li>
          <a target="_blank" :href="stamfordURL" class="btn red_btn"
          >STAMFORD TYRES</a>
        </li>
      </ul>
    </nav>

    <ul class="btn_group_list d-none d-lg-flex">
      <li>
        <a href="javascript:;" @click="goTO('contactUsHome')" class="btn blue_btn"
          >Contact Us</a
        >
      </li>
      <li>
        <a target="_blank" :href="stamfordURL" class="btn red_btn"
          >STAMFORD TYRES</a
        >
      </li>
    </ul>

    <div
      id="ToggleIcon"
      @click="showMobileMenu = !showMobileMenu"
      class="menu_bar d-block d-lg-none"
      :class="{ active: showMobileMenu }"
    ></div>
    <div
      id="menuOverlay"
      @click="showMobileMenu = !showMobileMenu"
      class="menu_overlay d-block d-lg-none"
      :class="{ active: showMobileMenu }"
    ></div>
  </div>
</header>
