<section class="fl_home_slider p-0">
  <div
    id="carouselExampleIndicators"
    class="carousel slide banner_slider_wrapper"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active" v-if="tyreRangeRoute == 0">
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-1">
              <!-- <p class="letter_space fw_700 text-uppercase">
                <b>LOREM IPSUM DOLOR</b>
              </p> -->
              <h1 class="heading_title title_2 white text-uppercase fst-italic">
                Discover the ultimate driving experience.
              </h1>
              <!-- <a href="#" class="btn blue_btn">LOREM IPSUM DOLOR</a> -->
            </div>
          </div>
          <img
            src="@/assets/images/falken/banner-splash-bg.webp"
            class="d-block w-100"
            alt="Falken Banner Image"
          />
            <iframe
              src="https://www.youtube.com/embed/pkWjSyjRDrE?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=pkWjSyjRDrE"
            ></iframe>
        </div>
      </div>

      <!-- This Comment code for falken Tyre Range  Start-->
      <div class="carousel-item active" v-else>
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-1">
              <!-- <p class="letter_space fw_700 text-uppercase">
                <b>LOREM IPSUM DOLOR</b>
              </p> -->
              <h1 class="heading_title title_2 white text-uppercase fst-italic">
                THE FALKEN TYRE RANGES
              </h1>
              <!-- <a href="#" class="btn blue_btn">LOREM IPSUM DOLOR</a> -->
            </div>
          </div>
          <img
            src="@/assets/images/falken/banner-splash-bg.webp"
            class="d-block w-100"
            alt="Falken Banner Image"
          />
            <iframe
              src="https://www.youtube.com/embed/5kyIpohwqQI?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=5kyIpohwqQI"
            ></iframe>
        </div>
      </div>
    </div>
  </div>
</section>
